import React, { useState } from "react";
import AddNysc from "./AddNysc";

function Nysc({ nyscData, refetchNysc, refetchStatus }) {
  const [openEditNyscModal, setOpenEditNyscModal] = useState(false);
  const [addNyscModal, setAddNyscModal] = useState(false);
  // const { data: nyscData, refetch } = useQuery("get-nysc-data", api.getNysc);

  return (
    <div>
      <div className="font-semibold text-xl text:darkBlue flex items-center gap-x-24">
        National Youth Service Corps
        <div
          className="cursor-pointer bi bi-plus-circle border border-primary text-primary p-2 rounded-lg text-base font-semibold"
          onClick={() => setAddNyscModal(true)}
          data-testid="add-nysc-cert-details"
        >
          &nbsp;Add/Update nysc certificate details
          {/* <img src={AddIcon} alt="add-icon" /> */}
        </div>
      </div>
      {addNyscModal && (
        <AddNysc
          closeModal={setAddNyscModal}
          refetchNysc={refetchNysc}
          refetchStatus={refetchStatus}
        />
      )}
      {nyscData?.data === null && (
        <div className="mt-10 text-center text-base font-medium text-lightGrey">
          Please update your Nysc details
        </div>
      )}

      {
        <>
          <div className="my-4">
          <span className="text-base">
              { nyscData?.data?.status==='completed'? 'Certificate Number' : 'Call-up Number'}
            </span> :{" "}
            <span className="font-medium text-lg">
              {" "}
              { 
                nyscData?.data?.status==='completed' ?
                 nyscData?.data?.certificate_number
                : nyscData?.data?.call_up_number
              }
            </span>
          </div>
          <div>
            <span className="text-base">Pop-Date</span> :{" "}
            <span className="font-medium text-lg">
              {`${new Date(nyscData?.data?.pop_date).toLocaleDateString('en-ng')}`}
            </span>
          </div>
        </>
      }
    </div>
  );
}

export default Nysc;
