import React, { useState, useEffect } from "react";
import Logo from "../../images/TestAssessify.png";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { exams } from "../../api/exams";
import { useSearchParams } from "react-router-dom";

const StartExam = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Track the loading state
  const [responseMessage, setResponseMessage] = useState(""); // Message for the user
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      const name = searchParams.get("name");
      const email = searchParams.get("email");
      const examId = searchParams.get("exam_id");
      const token = searchParams.get("token");
      const id = searchParams.get("id");
      const route = searchParams.get("route");

      window.sessionStorage.setItem("sshToken", token);
      window.sessionStorage.setItem("sshId", id);
      window.sessionStorage.setItem("sshRoute", route);

      try {
        const payload = {
          name: name,
          email: email,
          exam_id: examId,
        };

        const response = await exams.loginBasedLinkExam(payload);
        // Handle the success response
        if (response.data) {
          window.sessionStorage.setItem("userToken", response?.data?.token);
          window.sessionStorage.setItem(
            "active-assessment-id",
            response?.data?.user?.exam_id
          );
          window.sessionStorage.setItem(
            "user_id",
            response?.data?.user?.user_id
          );
          window.sessionStorage.setItem(
            "active-assessment-user-id",
            response?.data?.user?.user_exam_id
          );
          window.sessionStorage.setItem(
            "time_left",
            response?.data?.exam?.time_left
          );
          window.sessionStorage.setItem(
            "duration",
            response?.data?.exam?.duration
          );
          window.sessionStorage.setItem(
            "ql",
            response?.data?.exam?.number_of_questions
          );
          window.sessionStorage.setItem(
            "starting-index",
            response?.data?.exam?.number_of_questions_answered
          );
          navigate("/candidate/assessments/instructions");
          setResponseMessage(response.message);
        } else {
          // Show the message if no exam is found
          setResponseMessage(response.message);
        }
      } catch (error) {
        setResponseMessage(error.message);
      } finally {
        // Stop the loader
        setLoading(false);
      }
    };
    fetchData(); // Trigger the function
  }, []);

  return (
    <div className="bg-white min-h-screen p-4">
      <div className="grid place-content-center">
        <div className="p-6">
          <div className="flex justify-center mb-16">
            <img src={Logo} alt="testassessify-logo" className="w-fit h-14" />
          </div>
        </div>
        <div>{loading && <Loader />}</div>
        {!loading && responseMessage && (
          <div className="h-screen grid place-content-center text-center text-3xl text-primary mt-4">
            {responseMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default StartExam;
