import React, { useState, useEffect } from "react";
import { AssessementContext } from "../../../AssessmentContext";
import { useNavigate,Link } from "react-router-dom";
import { defaultState } from "../../../components/defaultState";
import { api } from "./../../../../../../api/index";
import ReactStars from "react-rating-stars-component";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-hot-toast";
// import { useCustomToast } from "../../components/customtoast/CustomToastProvider";
import { useCustomToast } from "../../../../../../components/customtoast/CustomToastProvider";

const CompletedAssessmentModal = () => {

  const { assessment, setAssessment } = React.useContext(AssessementContext);
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(10);
  const [autoMove, setAutoMove] = useState(false)
  const [feedback, setFeedback] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
   

  const { data: feedbackQuestion, refetch, isLoading } = useQuery(
    "get feedback questions",
    api.getFeedbackQuestions,
    {
      onSuccess: data => {
        const initialFeedback = data?.data?.map(question => ({
          id: question.id,
          response: null,  
          text: question?.text
        }));
          setFeedback(initialFeedback);
      }
    }
  );

   
  const closeModal = (navigation) => {
    setAssessment(defaultState);
    if (navigation === "history") navigate("/candidate/exam-history");
    else navigate("/candidate/assessments/view-assessments");
    document.exitFullscreen();
  };


  const customToast = useCustomToast();
  const [rating, setRating] = useState(0);
  const [text, setText] = useState("");
  const [answers, setAnswers] = useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
    q5: null,
  });

  const handleRadioChange = (questionId, response) => {
    setFeedback(prevFeedback => (
      prevFeedback.map(item => (
        item.id === questionId ? { ...item, response } : item
      ))
    ));
  };

  const ratingLabel = (rating) => {
    if (rating == 0.5 || rating == 1) {
      return "Bad";
    } else if (rating == 1.5 || rating == 2) {
      return "Fair";
    } else if (rating == 2.5 || rating == 3) {
      return "Good";
    } else if (rating == 3.5 || rating == 4) {
      return "Very Good";
    } else {
      return "Excellent";
    }
  };

  const ratingChanged = (newRating) => {
    setRating(newRating);
    
    let label = "";
    if (newRating == 0.5 || newRating == 1) {
      label = "Bad";
    } else if (newRating == 1.5 || newRating == 2) {
      label = "Fair";
    } else if (newRating == 2.5 || newRating == 3) {
      label = "Good";
    } else if (newRating == 3.5 || newRating == 4) {
      label = "Very Good";
    } else {
      label = "Excellent";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let content = {
      comment: text,
      rating: rating,
    };
    const feedbackSubmitted = feedback.filter( feedback => {
       return feedback?.response !== null
    } )
    if(feedbackSubmitted?.length){          
         setErrorMessage('')
         const feedbackQuestions = feedbackSubmitted.filter( question => question.response !== null )
         try {
          toast.loading("loading...");          
          const res = await api.sendFeedback({ 
                ...content, 
                questions: feedbackQuestions.map( question => {
                  return { id: question?.id, response: question?.response }
                })
           });
          toast.remove();
          customToast.success(res.message);
          navigate("/candidate/exam-history");
        } catch (error) {
          customToast.error(error.response.data.error);
          toast.remove()
        }
    }
    else setErrorMessage('No any feedback choosen')     
  };


  return (
    <div
      className="h-[100vh] w-[100vw] bg-[#eee] fixed top-0 left-0 grid place-items-center p-16"
      style={{ zIndex: 9000000 }}
    >  
      <div className="w-full h-full flex items-center justify-center ">
        <div className={`${ !autoMove ? 'h-[100vh] w-full': 'h-[350px] md:h-[500px] w-full md:w-[40vw] '}  bg-white h-auto rounded-lg p-8`}>
         <div className="flex justify-end items-end">
             <Link
              to=""
              className="text-[#F35B24] text-lg"
              onClick={() => closeModal("")}
            >
              { 'View Upcoming Exams' }
            </Link>
         </div>  
          <div className="flex justify-center">
            <div className="flex flex-col mb-10">
              <div className="flex justify-between items-center">
                <div> 
                  { autoMove && <img 
                    src="/images/checklist-with-box-and-green-label-check-mark-for-assignment-or-exam-results-vector.jpg" 
                     alt="sucessful exam image"
                     className="block w-[250px] h-[250px] mt-5 mx-auto mb-2" 
                  />}
                  <h3 className="text-primary font-semibold text-2xl mb-5 text-center">
                    Assessment Completed Successfully{" "}
                  </h3>
                  {autoMove && <div className="flex justify-between mt-5 bg-green-100 border border-green-100 py-5 text-green-500 px-3 mb-3 text-lg">
                    <p className="text-lg">
                     You will be redirected to your next assessment <strong>{seconds}</strong> seconds. 
                    </p>
                     </div>}
                </div>
              </div>
            </div>
          </div>
          { !autoMove && <>
          <p className="font-semibold text-center text-base text-darkBlue mb-9">
              You have successfully completed your assessment on Numeracy.{" "}
            </p>
            <p className="font-semibold text-center text-base capitalize mb-6">
              Kindly provide feedback to help us improve on your experience
            </p>
            <p className="text-primary text-center text-2xl font-bold text-center">
              How will you rate your experience 
            </p>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center mb-16">
              <div className="">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={44}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ffd700"
                />
                <div className="text-center mt-2 text-gray-600">
                  {rating > 0 && `${ratingLabel(rating)}`}
                </div>
              </div>
            </div>
            <div className="">
              <h3 className="mb-6 text-xl font-bold ">
                Tell Us more about Your Experience
              </h3>
              <div className="w-3/4 flex flex-col">
                 {
                    (!isLoading && feedback.length ) ?
                    feedback.map(question => (
                      <div key={question.id}>
                        <p className="text-lg my-3">{question.text}</p>
                         <div className="flex items-center"> 
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name={`question_${question.id}`}
                              value="true"
                              checked={feedback.find(item => item.id === question.id)?.response === true}
                              onChange={() => handleRadioChange(question.id, true)}
                              className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                            />
                            Yes
                          </label>
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name={`question_${question.id}`}
                              value="false"
                              checked={feedback.find(item => item.id === question.id)?.response === false}
                              onChange={() => handleRadioChange(question.id, false)}
                              className={`ml-8 relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                            />
                            No
                          </label>
                         </div>
                      </div>
                    ))
                    :
                    <p className="text-center mt-12 text-lg">Loading...</p>
                 }               
                </div>
                <p className="text-red-500 text-xl">
                    {errorMessage}
                </p>
            </div>
            <div className="">
              <h3 className="text-base font-medium mb-6 mt-3">
                Is there anything you will like us to improve upon?
              </h3>
              <div className="flex gap-x-56 items-end">
                <textarea
                  className="w-2/4 h-32 p-2 bg-[#F2F3F7] border border-lightGrey rounded-lg focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent"
                  placeholder="Leave your comment here."
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                ></textarea>
               </div>
              </div>
              <div className="flex flex-wrap md:px-5 justify-center md:justify-between border border-t-2 border-gray-200 mt-5 px-3 py-3">
                <button
                   type="button"
                  onClick={() => closeModal("history")}
                  className="mt-3 md:mt-0 bg-green-500 py-3 px-16 rounded-lg text-white text-lg"
                >
                  View Exams History
                </button>
                <button
                  type="submit"
                  className="bg-darkBlue px-8 py-3 font-bold text-lg text-white rounded-lg"
                >
                  Submit Feedback
                </button>
           </div>
            </form>
          </>} 
        </div>
        </div>
    </div>
  );
};

export default CompletedAssessmentModal;
