const { createApiClient } = require("./client");

const client = createApiClient()

export const profile = {
   // get educations 
    getUserEducationListv2: (id) =>
        client.get(`/candidates/${id}/profile/education`).then(({ data }) => data),

    createUserEducationv2: ({id, payload}) =>
        client
          .post(`/candidates/${id}/profile/education`, { ...payload })
          .then(({ data }) => data),
    
    getUserBiodatav2: ({id}) =>
      client.get(`/candidates/${id}/profile/bio-data`).then(({ data }) => data),

    getUserExperienceListv2: ({id}) =>
        client.get(`/candidates/${id}/profile/experiences`).then(({ data }) => data),

    getNyscv2: ({id}) =>
        client.get(`/candidates/${id}/profile/nysc-data`).then(({ data }) => data),
     
    getProfileStatusv2: ({id}) =>
        client.get(`/candidates/${id}/status`).then(({ data }) => data),

    updateUserBiodatav2: ({id, payload}) =>
        client
          .patch(`/candidates/${id}/profile/bio-data`, { ...payload })
          .then(({ data }) => data),

    getInstitutionsv2: (search) =>
        client
        .get(
        `institutions?per_page=10${
            typeof search === "string" ? `&search=${search}` : ""
        }`
        )
        .then(({ data }) => data),

    getCoursesv2: (search) =>
        client
        .get(
        `courses?per_page=10${
            typeof search === "string" ? `&search=${search}` : ""
        }`
        )
        .then(({ data }) => data),

    getStatesv2: () => client.get(`states?per_page=40`).then(({ data }) => data),

    getDegreesv2: () => client.get(`degrees`).then(({ data }) => data),

    getClassOfDegreesv2: () =>
    client.get(`class-of-degrees`).then(({ data }) => data),

 updateUserEducationv2: ({id, data, userId}) =>
    client
        .patch(`/candidates/${userId}/profile/education/${id}`, {...data})
        .then(({ data }) => data),

deleteUserEducationv2: (id, userId) =>
 client
    .delete(`/candidates/${userId}/profile/education/${id}`)
    .then(({ data }) => data),

createUserExperiencev2: ({id,payload}) =>
 client
    .post(`/candidates/${id}/profile/experiences`, { ...payload })
    .then(({ data }) => data),
       
updateUserExperiencev2: ({id, data, userId}) =>
 client
    .patch(`/candidates/${userId}/profile/experiences/${id}`, {...data })
    .then(({ data }) => data),

deleteUserExperiencev2: (id, userID) =>
    client
        .delete(`/candidates/${userID}/profile/experiences/${id}`)
        .then(({ data }) => data),

AddNyscDatav2: ({payload, id}) =>
    client
        .post(`/candidates/${id}/profile/nysc-data`, { ...payload })
        .then(({ data }) => data),

 validateProfilev2: (payload) =>
    client
        .post(`candidate/update-profile/${payload}`)
        .then(({ data }) => data),
}